import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import "./index.scss";
import Banner from "../components/banner";
import bannerImg from "../images/home/hero-banner.jpg";
import bannerImgMobile from "../images/home/hero-banner-mobile.png";
import Button from "../components/button";
import MoveInfo from "../components/moveInfo";
import TwoCol from "../components/twoCol/twoCol";
import treatmentImg from "../images/home/treatment-img.jpg";
import donaldThumbnail from "../images/home/donald-thumbnail.jpg";
import instructorImg from "../images/home/instructor.jpg";
import diabeticImg from "../images/home/diabetic-img.jpg";
import buttonExternalIcon from "../images/icons/external-icon.svg";
import StickyButton from "../components/stickyButton";
import { Link } from "gatsby";
import ExternalLink from "../components/modals/externalLink";
import VideoModal from "../components/modals/video-modal";

const TranscriptQutenzaApplication = () => {
  return (
    <>
      <h2>Meet Donald, a real patient.</h2>
      <p className="bold no-btm-spacer">Voice Over (V.O.):</p>
      <p>
        It took me a while to make the connections, um, and it came in stages.
        Why was the pain occurring, why was my skin so sensitive. The feeling
        that something was invading my skin, cutting my skin—it was quite
        painful. And I knew it was different, but I had, I never made the
        connection between the diabetes and the diabetic nerve pain.
      </p>
      <p>
        I was diagnosed as a diabetic in 2007. Having diabetic neuropathy is a
        very challenging experience. The disease constantly forces you to
        adjust. It’s very painful. Uh, there’s numbness, and there’s tingling,
        and there’s a cutting and burning sensation. I thought that they would
        go, eventually go away.
      </p>
      <p>
        I took it on my own to start Googling. Through the research I found some
        answers, but I didn’t find all of the answers. It took a while for me to
        actually go out and find that right type of doctor. You could have all
        these doctors out here in the world, but you gotta find someone who’ll
        help you do this. Get you a endocrinologist who you can relate to and
        who relates to you, who understand what you’re experiencing, the pain,
        diabetic, uh, pain, and peripheral neuropathy.
      </p>
      <p>
        The very first thing that I would tell individuals who are suffering
        from diabetes neuropathy or diabetes pain is to, number one, educate
        yourself, find you a quality neurologist, and to believe that this can
        get better.
      </p>
    </>
  );
};

const IndexPage = () => {
  return (
    <HcpLayout pageClass="hcp-homepage">
      <section className="hero-banner">
        <Banner
          extraClass="gradient-overlay"
          bannerImg={bannerImg}
          bannerImgMobile={bannerImgMobile}
          bannerImgAlt="Woman crouching while smiling at and petting her dog"
          bannerTitle="Don’t let diabetic nerve pain define you."
          bannerDesc={`<p>If you’re living with diabetes and are experiencing foot pain, you may have diabetic nerve pain, also known as diabetic peripheral neuropathy (DPN).</p>`}
          nextSecId="#info"
        />
        <StickyButton />
      </section>

      <section className="info-block" id="info">
        <div className="centered-content">
          <div className="content">
            <h2>
              <strong>
                Empower yourself with information about this common condition
              </strong>
              . Then, talk to your doctor about options to help you get the pain
              relief you need.
            </h2>
            <div className="btn-wrap">
              <Link to="/understanding-dpn/">
                <Button
                  className="btn-primary btn-lg border-36"
                  btnTitle="Understand Diabetic Nerve Pain"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="move-block">
        <MoveInfo />
      </section>

      <section className="two-col__block">
        <div className="centered-content">
          <TwoCol
            extraClass="align-center"
            image={treatmentImg}
            imageAlt="Man holding a fishing rod and fishing by a lake."
            heading="Treatment options to manage diabetic nerve pain of the feet"
            description={`<p>Get information on treatments available to help control foot pain.</p>`}
            btnUrl="/treatment-options/"
            btnclass="btn-primary btn-md border-32"
            btnTitle="See Treatment Options"
          ></TwoCol>
        </div>
      </section>
      <section className="video-block">
        <div className="centered-content">
          <VideoModal
            className="video-thumb-lg"
            // url={"tokzrrcp97"}
            url={"rjqyhwneta"}
            thumbnail={donaldThumbnail}
            thumbnailAlt="Male patient sitting for an interview"
            title="“It made a difference to find the right specialist and treatment.”"
            authorName="Donald, living with DPN of the feet."
          >
            <TranscriptQutenzaApplication />
          </VideoModal>
        </div>
      </section>

      <section className="two-col__block">
        <div className="centered-content">
          <TwoCol
            extraClass="align-center"
            image={instructorImg}
            imageAlt="Doctor smiling at her patient while pointing at a tablet."
            heading="Discuss your symptoms with your doctor"
            description={`<p>Think you might have diabetic nerve pain of the feet? Tell your doctor about your painful feet and other symptoms.</p>`}
            btnUrl="/talking-with-your-doctor/"
            btnclass="btn-primary btn-md border-32"
            btnTitle="Talk with Your Doctor"
            tagText="Actor portrayals."
          ></TwoCol>
        </div>
      </section>

      <section className="two-col__block">
        <div className="centered-content">
          <TwoCol
            extraClass="align-center reverse"
            image={diabeticImg}
            imageAlt="Woman smiling while hiking with mountains around her."
            heading="A direct way to relieve diabetic nerve pain of the feet"
            description={`<p>Learn about a unique diabetic foot pain treatment.</p>`}
          >
            <div className="btn-wrap">
              <ExternalLink
                title="Discover a Targeted Treatment"
                extraClass="modal-lg"
                href="https://www.qutenza.com/dpn/about-qutenza/"
              >
                <Button
                  className="btn-primary btn-lg border-36"
                  btnTitle="Discover a Targeted Treatment"
                  btnIcon={buttonExternalIcon}
                />
              </ExternalLink>
            </div>
          </TwoCol>
        </div>
      </section>
    </HcpLayout>
  );
};

export default IndexPage;

export const Head = () => <Seo title="Diabetic Peripheral Neuropathy" />;
