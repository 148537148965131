import React from "react";
import "./tileIcon.scss";
import { Link } from "gatsby";

function TileIcon(props) {
  return (
    <div className={`tile-icon ${props.extraClass}`}>
      {props.icon && (
        <Link to={props.url}>
          <div className="icon">
            <img src={props.icon} alt={props.alt} />
          </div>
        </Link>
      )}
      <div className="content">
        {/* <h4>{props.title}</h4> */}
        <h4 dangerouslySetInnerHTML={{ __html: props.title }} />
        <div dangerouslySetInnerHTML={{ __html: props.desc }}></div>
      </div>
    </div>
  );
}

export default TileIcon;
