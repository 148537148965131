import React from "react";
import "./twoCol.scss";
import { Col, Row } from "react-grid-system";
import Button from "../button";
let tagText = "Actor portrayal.";
function TwoCol(props) {
  return (
    <div className="two-col">
      <Row className={`row ${props.extraClass}`}>
        <Col lg={6} className="col-left">
          <div className="two-col__media border-32">
            <img src={props.image} alt={props.imageAlt} />
            <span className={`tag ${props.className ? props.className : ""}`}>
              {props.tagText ?? tagText}
            </span>
          </div>
        </Col>
        <Col lg={6} className="col-right">
          <div className="two-col__content">
            {props.heading && <h2>{props.heading}</h2>}
            {props.description && (
              <div
                dangerouslySetInnerHTML={{ __html: props.description }}
              ></div>
            )}
            {props.children}
            <div className="btn-wrap">
              {props.newTab ? (
                <a href={props.btnUrl} target="_blank" rel="noreferrer">
                  <Button
                    className={props.btnclass}
                    btnTitle={props.btnTitle}
                    btnIcon={props.btnIcon}
                  />
                </a>
              ) : (
                <a href={props.btnUrl}>
                  <Button
                    className={props.btnclass}
                    btnTitle={props.btnTitle}
                    btnIcon={props.btnIcon}
                  />
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default TwoCol;
