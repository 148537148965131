import React from 'react';
import "./cardvideo.scss";
import playIcon from "../../../images/icons/play-icon.svg";
import topWave from "../../../images/video-card-top-curve-m.png";
import bottomWave from "../../../images/video-card-bottom-curve-m.png";
import WistiaPlayerModal from '../../WistiaPlayer';

function Cardvideo(props) {
    return (
        <div className='card-video bg-cover gradient-overlay'>
            <img src={props.thumbnailImg} alt={props.thumbnailAlt} />
            <div className="top-wave">
                <img src={topWave} alt="top curve" />
            </div>
            <div className="bottom-wave">
                <img src={bottomWave} alt="Bottom curve" />
            </div>
            <div className="card-video__content">
                <div className="icon for-desktop">
                    <img src={playIcon} alt="Play Icon" />
                </div>
            </div>
            <div className="info">
                <div className="icon for-mobile">
                    <img src={playIcon} alt="Play Icon" />
                </div>
                <h2 className='text-white'>{props.title}</h2>
                {props.authorName &&
                    <span className='tag'>{props.authorName}</span>
                }
            </div>
         
        </div>
    );
}

export default Cardvideo;