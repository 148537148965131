import React, { useState, useEffect } from 'react';
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import "./404.scss";
import bannerImg from "../images/404/error-banner.jpg";
import bannerImgMobile from "../images/404/error-banner-mobile.png";
import Button from "../components/button";
import { Link } from "gatsby";
import waveBottomImg from "../images/404/error-bottom-curve.png";
import waveTopImg from "../images/curves-top.svg";
import waveTopImgMobile from "../images/curves-top-m.svg";
import waveBottomImgMobile from "../images/curve-bottom-m.png";
import downArrow from "../images/icons/down-arrow-icon.svg";

const NotFoundPage = () => {
  // Initialize state without relying on the window object
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth < 576);
    };

    // Set the initial value correctly in the browser environment
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HcpLayout pageClass="error-page">
      <section className="hero-banner custom-404-banner">
        <div className="banner bg-cover gradient-overlay banner-inner">
          <img src={isMobile ? bannerImgMobile : bannerImg} className="banner-img" alt="Banner" />

          <div className="top-wave">
            <img src={isMobile ? waveTopImgMobile : waveTopImg} className="top-wave-img" alt="Wave shape" />
          </div>
          <div className="bottom-wave">
            <img src={isMobile ? waveBottomImgMobile : waveBottomImg} className="bottom-wave-img" alt="Wave shape" />
          </div>

          <div className="centered-content">
            <div className="banner-content text-white">
              <h1>404</h1>
              <h2>Page Missing</h2>
              <div className="btn-wrap">
                <a href="#info-block" className="icon">
                  <img src={downArrow} alt="Down Arrow Icon" />
                </a>
              </div>
            </div>
            <div className="tag-text">
              <span> Actor portrayal. </span>
            </div>
          </div>
        </div>
      </section>

      <section className="info-block" id="info-block">
        <div className="centered-content">
          <div className="content">
            <h3>
              We’re sorry, the page you requested does not exist. Please check
              the address you have entered and try again.
            </h3>
            <div className="btn-wrap">
              <Link to="/">
                <Button
                  className="btn-primary btn-lg border-36"
                  btnTitle="Return to Homepage"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </HcpLayout>
  );
};

export default NotFoundPage;

export const Head = () => <Seo title="Page Not Found" />;
