import React, { useState } from 'react';

const TextField = React.forwardRef(({ label, placeholder, type, error, className,onBlur,onChange, ...rest }, ref) => {
  const [showLabel, setShowLabel] = useState(false);

  const handleFocus = (e) => {
    setShowLabel(true)
  }
  const handleBlur = (e) => {
    onBlur(e)
    !e.target.value && setShowLabel(false)
  }
  const handleChange = (e)=>{
    onChange(e);
     setShowLabel(e.target.value?true:false)
  }
  

  return (
    <div className={`form-group ${showLabel ? 'show-label' : ''} ${error ? 'error' : ''}`}>
      <label>{label}</label>
      <input
        type={type ?? 'text'}
        placeholder={placeholder}
        className={`form-control ${className ?? ''}`}
        {...rest}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        ref={ref}
      />
    </div>
  );
})

export default TextField;