import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import "./thank-you.scss";
import Banner from "../components/banner";
import bannerImg from "../images/get-updates/get-updates-banner.png";
import bannerImgMobile from "../images/get-updates/get-updates-banner-mobile.png";
import waveBottomImg from "../images/get-updates/get-updated-bottom-wave.png";

const GetUpdatesPage = () => {

  return (
    <HcpLayout pageClass="thank-you-page">
      <section className="hero-banner">
        <Banner
          extraClass="gradient-overlay banner-inner"
          bannerImg={bannerImg}
          bannerImgMobile={bannerImgMobile}
          bannerImgAlt="Grandmother and grandchild smiling while gardening together in sun hats"
          bannerTitle="Welcome to information <nobr>and support</nobr>"
          bottomWaveDesktop={waveBottomImg}
          tagText="Actor portrayals."
        />
      </section>
      <div
        style={{ display: "block" }}
        className={`thank-you-section form-submitted`}
      >
        <div className="centered-content">
          <h2 className="small-h2">
            Congratulations on making a M.O.V.E. You have successfully signed
            up.
          </h2>
          <p>
            As a next step, download the Doctor Discussion Guide to help you
            start a conversation with your doctor.
          </p>
          <div className="btn-wrap">
            <a
              href="/pdf/unbranded-doctor-discussion-guide.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-primary btn-lg border-32">
                Download Now
              </button>
            </a>
          </div>
        </div>
      </div>
    </HcpLayout>
  );
};

export default GetUpdatesPage;

export const Head = () => <Seo title="Thank you" />;
