import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import "./treatment-options.scss";
import Banner from "../components/banner";
import bannerImg from "../images/treatment-options/treatment-banner-img.jpg";
import bannerImgMobile from "../images/treatment-options/treatment-banner-mobile.png";
import Button from "../components/button";
import { Col, Row } from "react-grid-system";
import CardInfo from "../components/cards/cardInfo";
import buttonExternalIcon from "../images/icons/external-icon.svg";
import TileIcon from "../components/tileIcon";
import messageIcon from "../images/icons/message-icon.svg";
import rightIcon from "../images/icons/right-arrow.svg";
import ExternalLink from "../components/modals/externalLink";
import StickyButton from "../components/stickyButton";

const TreatmentOptionsPage = () => {
  return (
    <HcpLayout pageClass="treatment-options-page">
      <section className="hero-banner">
        <Banner
          extraClass="gradient-overlay"
          bannerImg={bannerImg}
          bannerImgMobile={bannerImgMobile}
          bannerImgAlt="Man and woman doing yoga in an open parking lot at sunset"
          bannerTitle="Treatment options for diabetic nerve pain"
          nextSecId="#treatment-info"
          tagText="Actor portrayals."
        />
        <StickyButton />
      </section>

      <section className="info-block" id="treatment-info">
        <div className="centered-content">
          <div className="content">
            <h2>
              For diabetic nerve pain, there are various treatment options. In
              addition to over-the-counter treatments and surgical procedures
              and implants, there are several prescription treatments available.
            </h2>
          </div>
        </div>
      </section>

      <section className="card-wrapper">
        <div className="centered-content">
          <Row className="row">
            <Col lg={4} md={6} className="col">
              <CardInfo
                title="FDA-approved topical treatment"
                desc={`<p>Prescription-strength topical treatment therapy.</p>`}
              />
            </Col>
            <Col lg={4} md={6} className="col">
              <CardInfo
                title="Antidepressants and anticonvulsants"
                desc={`<p>Common oral therapies that are prescribed for diabetic nerve pain of the feet.</p>`}
              />
            </Col>
            <Col lg={4} md={6} className="col">
              <CardInfo
                title="Narcotics"
                desc={`<p>Stronger medication when other therapies do not provide relief from painful feet. Can be addictive.</p>`}
              />
            </Col>
          </Row>
          <div className="btn-wrap">
            <ExternalLink
              title="Discover a Targeted Treatment"
              extraClass="modal-lg"
              href="https://www.qutenza.com/dpn/about-qutenza/"
            >
              <Button
                className="btn-primary btn-lg border-36"
                btnTitle="Discover a Targeted Treatment"
                btnIcon={buttonExternalIcon}
              />
            </ExternalLink>
          </div>
        </div>
      </section>

      <section className="target-block">
        <div className="centered-content">
          <Row className="row">
            <Col lg={6} className="col-left">
              <TileIcon
                url="/get-updates/"
                icon={messageIcon}
                title="Get Updates"
                desc={`<p>Sign up to get educational and personalized resources you can share with your family, friends, and healthcare provider.</p>
                            `}
              />
            </Col>
            <Col lg={6} className="col-right">
              <TileIcon
                extraClass="tile-icon--alt"
                url="/talking-with-your-doctor/"
                icon={rightIcon}
                title="Start a Conversation with <nobr>Your Doctor</nobr>"
                desc={`<p>Speak with your doctor about your nerve pain symptoms and identify the best treatment plan for you.</p>
                            `}
              />
            </Col>
          </Row>
        </div>
      </section>
    </HcpLayout>
  );
};

export default TreatmentOptionsPage;

export const Head = () => <Seo title="Treatment Options" />;
